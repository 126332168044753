import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import CustomersLogos from '../components/CustomersLogos';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import SectionOnlyFooter from '../components/SectionOnlyFooter';
import SectionHeaderWithFooter from '../components/SectionHeaderWithFooter';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('wemapProPage');

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_WEMAP_PRO')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_WEMAP_PRO')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>
                    <CustomersLogos
                        title={t('TITLE_WEMAP_PRO')}
                        size='large'
                        customersLogos={[
                            {image: '/images/products/wemap-pro/france-3.svg'},
                            {image: '/images/products/wemap-pro/depeche.svg'},
                            {image: '/images/products/wemap-pro/logo_nimes.svg'},
                            {image: '/images/products/wemap-pro/region-haut-de-france.svg'}
                        ]} />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_ONLINE_TOOL')}
                        cellParagraph={t('DESCRIPTION_TOOLS_CREATE_SCALE')}
                        videoUrl2='/images/products/wemap-pro/wemap-pro-wysiyg.mp4'
                        pictureRight={data.ipadMockup.childImageSharp}
                        altDescription='series of mobiles with view of the map creator'
                        titleButton={t('TITLE_BUTTON_GET_STARTED')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map22',
                                text: t('DESCRIPTION_WYSIWYG')
                            },
                            {
                                icon: 'icon-mobile',
                                text: t('DESCRIPTION_TAYLOR_MAP')
                            },
                            {
                                icon: 'icon-location1',
                                text: t('DESCRIPTION_ADVANCED_OPTION')
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons
                            title={t('TITLE_PERSONALIZE')}
                            text={t('DESCRIPTION_PERSONALIZE')}
                            titleButton={t('BUTTON_TALK_ABOUT_PROJECT')}
                            icons={[
                                {
                                    icon: 'icon-palette',
                                    text: t('DESCRIPTION_STYLING')
                                }, {
                                    icon: 'icon-chat',
                                    text: t('DESCRIPTION_PREFERENCES')
                                },
                                {
                                    icon: 'icon-layers',
                                    text: t('DESCRIPTION_YOUR_STREAMS')
                                },
                                {
                                    icon: 'icon-group',
                                    text: t('DESCRIPTION_PUBLISH')
                                }
                            ]}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <TwoColumnsLayout>
                        <TwoColumnsIcons
                            title={t('TITLE_EMBED')}
                            text={t('DESCRIPTION_EMBED')}
                            titleButton={t('TITLE_BUTTON_LEARN_MORE')}
                            icons={[
                                {
                                    icon: 'icon-checkmark',
                                    text: t('DESCRIPTION_SNIPPET')
                                }, {
                                    icon: 'icon-spinner',
                                    text: t('DESCRIPTION_UPDATES')
                                },
                                {
                                    icon: 'icon-lock-closed',
                                    text: t('DESCRIPTION_SECURE')
                                }
                            ]}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_LIVE_FEEDS')}
                        cellParagraph={t('DESCRIPTION_EMBEDDED_MAPS')}
                        pictureRight={data.platformPro.childImageSharp}
                        altDescription='series of mobiles with view of the map creator'
                        titleButton={t('TITLE_BUTTON_DISCOVER_WEMAP')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[

                            {
                                icon: 'icon-bullhorn',
                                text: t('DESCRIPTION_LIVE_FEEDS')
                            },
                            {
                                icon: 'icon-bullhorn',
                                text: t('DESCRIPTION_STREAM')
                            },
                            {
                                icon: 'icon-documents',
                                text: t('DESCRIPTION_IMPORT')
                            },
                            {
                                icon: 'icon-map22',
                                text: t('DESCRIPTION_GEOTAGG')
                            }
                        ]}
                    />

                    <SectionHeaderWithFooter
                        titleSection={t('TITLE_CARD_WEMAP_MARKET_PLACE')}
                        titleButton={t('TITLE_TRY_OUT_WEMAP_PRO')}
                        altDescription='picture of iphone with navigation in augmented reality'
                        urlFooter='/contact/'
                        infosCards={[
                            {
                                firstImageUrl: '/images/products/icon_facebook.png',
                                secondImageUrl: '/images/products/icon_twitter.png',
                                thirdImageUrl: '/images/products/icon_instagram.png',
                                fourthImageUrl: '/images/products/icon_youtube.png',
                                fifthImageUrl: '/images/products/icon_rss_feed.png',
                                sixthImageUrl: '/images/products/icon_uber.png',
                                firstTextIcon: t('TITLE_CARD_ICON_FACEBOOK'),
                                secondTextIcon: t('TITLE_CARD_ICON_TWITTER'),
                                thirdTextIcon: t('TITLE_CARD_ICON_INSTAGRAM'),
                                fourthTextIcon: t('TITLE_CARD_ICON_YOUTUBE'),
                                fifthTextIcon: t('TITLE_CARD_ICON_RSS_FEED'),
                                sixthTextIcon: t('TITLE_CARD_ICON_UBER')
                            },
                            {
                                firstImageUrl: '/images/products/icon_spreadsheet.png',
                                secondImageUrl: '/images/products/icon_excel.png',
                                thirdImageUrl: '/images/products/icon_ical.png',
                                fourthImageUrl: '/images/products/icon_eventbrite.png',
                                fifthImageUrl: '/images/products/icon_like.png',
                                sixthImageUrl: '/images/products/icon_save.png',
                                firstTextIcon: t('TITLE_CARD_ICON_GOOGLE_SHEETS'),
                                secondTextIcon: t('TITLE_CARD_ICON_EXCEL'),
                                thirdTextIcon: t('TITLE_CARD_ICON_ICAL'),
                                fourthTextIcon: t('TITLE_CARD_ICON_STYLE'),
                                fifthTextIcon: t('TITLE_CARD_ICON_LIKE'),
                                sixthTextIcon: t('TITLE_CARD_ICON_SAVE')
                            },
                            {
                                firstImageUrl: '/images/products/icon_createlist.png',
                                secondImageUrl: '/images/products/icon_share.png',
                                thirdImageUrl: '/images/products/icon_itineraries.png',
                                fourthImageUrl: '/images/products/icon_service.png',
                                fifthImageUrl: '/images/products/icon_api.png',
                                firstTextIcon: t('TITLE_CARD_ICON_CREATE_LIST'),
                                secondTextIcon: t('TITLE_CARD_ICON_SHARE'),
                                thirdTextIcon: t('TITLE_CARD_ICON_ITINERARIES'),
                                fourthTextIcon: t('TITLE_CARD_ICON_SERVICES'),
                                fifthTextIcon: t('TITLE_CARD_ICON_API')
                            }
                        ]}

                        marketIconsConnector={[
                            {
                                imageUrl: '/images/products/icon_api.png',
                                titleIcon: t('TITLE_CARD_ICON_API'),
                                textIcon: t('DESCRIPTION_CARD_ICON_API')
                            },
                            {
                                imageUrl: '/images/products/icon_service.png',
                                titleIcon: t('TITLE_CARD_ICON_SERVICES'),
                                textIcon: t('DESCRIPTION_CARD_ICON_SERVICES')
                            },
                            {
                                imageUrl: '/images/products/icon_createlist.png',
                                titleIcon: t('TITLE_CARD_ICON_CREATE_LIST'),
                                textIcon: t('DESCRIPTION_CARD_ICON_CREATE_LIST')
                            },
                            {
                                imageUrl: '/images/products/icon_spreadsheet.png',
                                titleIcon: t('TITLE_CARD_ICON_GOOGLE_SHEETS'),
                                textIcon: t('DESCRIPTION_CARD_ICON_GOOGLE_SHEETS')
                            },
                            {
                                imageUrl: '/images/products/icon_itineraries.png',
                                titleIcon: t('TITLE_CARD_ICON_ITINERARIES'),
                                textIcon: t('DESCRIPTION_CARD_ICON_ITINERARIES')
                            },
                            {
                                imageUrl: '/images/products/icon_share.png',
                                titleIcon: t('TITLE_CARD_ICON_SHARE'),
                                textIcon: t('DESCRIPTION_CARD_ICON_SHARE')
                            },
                            {
                                imageUrl: '/images/products/icon_excel.png',
                                titleIcon: t('TITLE_CARD_ICON_EXCEL'),
                                textIcon: t('DESCRIPTION_CARD_ICON_EXCEL')
                            },
                            {
                                imageUrl: '/images/products/icon_ical.png',
                                titleIcon: t('TITLE_CARD_ICON_ICAL'),
                                textIcon: t('DESCRIPTION_CARD_ICON_ICAL')
                            },
                            {
                                imageUrl: '/images/products/icon_twitter.png',
                                titleIcon: t('TITLE_CARD_ICON_TWITTER'),
                                textIcon: t('DESCRIPTION_CARD_ICON_TWITTER')
                            },
                            {
                                imageUrl: '/images/products/icon_youtube.png',
                                titleIcon: t('TITLE_CARD_ICON_YOUTUBE'),
                                textIcon: t('DESCRIPTION_CARD_ICON_YOUTUBE')
                            },
                            {
                                imageUrl: '/images/products/icon_eventbrite.png',
                                titleIcon: t('TITLE_CARD_ICON_STYLE'),
                                textIcon: t('DESCRIPTION_CARD_ICON_STYLE')
                            },
                            {
                                imageUrl: '/images/products/icon_instagram.png',
                                titleIcon: t('TITLE_CARD_ICON_INSTAGRAM'),
                                textIcon: t('DESCRIPTION_CARD_ICON_INSTAGRAM')
                            },
                            {
                                imageUrl: '/images/products/icon_rss_feed.png',
                                titleIcon: t('TITLE_CARD_ICON_RSS_FEED'),
                                textIcon: t('DESCRIPTION_CARD_ICON_RSS_FEED')
                            },
                            {
                                imageUrl: '/images/products/icon_like.png',
                                titleIcon: t('TITLE_CARD_ICON_LIKE'),
                                textIcon: t('DESCRIPTION_CARD_ICON_LIKE')
                            },
                            {
                                imageUrl: '/images/products/icon_facebook.png',
                                titleIcon: t('TITLE_CARD_ICON_FACEBOOK'),
                                textIcon: t('DESCRIPTION_CARD_ICON_FACEBOOK')
                            },
                            {
                                imageUrl: '/images/products/icon_uber.png',
                                titleIcon: t('TITLE_CARD_ICON_UBER'),
                                textIcon: t('DESCRIPTION_CARD_ICON_UBER')
                            },
                            {
                                imageUrl: '/images/products/icon_save.png',
                                titleIcon: t('TITLE_CARD_ICON_SAVE'),
                                textIcon: t('DESCRIPTION_CARD_ICON_SAVE')
                            }
                        ]}
                    />

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with view of Wemap map open on computer and mobile'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/'
                    />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  banner: file(relativePath: {eq: "products/wemap-pro/banner_wemap_platform.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  ipadMockup: file(relativePath: {eq: "products/wemap-pro/ipad-mockup.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 400, layout: FIXED)
    }
  }
  platformPro: file(relativePath: {eq: "products/wemap-pro/platform_wemap.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 420, layout: FIXED)
    }
  }
}
`;
