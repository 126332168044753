import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PrimaryButton from '../components/PrimaryButton';
import LocalizedLink from '../components/LocalizedLink';

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 7% 0%;

`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    background-color:#217EE6;
    color: #FFF;
    padding: 4% 30% 9% 9%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 5%;

    @media (max-width: 767px) {
        flex-direction:column !important;
        padding: 10% !important;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        padding: 5% 10%;
    }
`;


const LeftCellTitle = styled.h2`
    font-size: 23px;
    line-height: 34px;
    font-weight: 200;
`;

const MarketLayout = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #FFF;
    box-sizing: border-box;
    padding-bottom: 6%;
`;

const ContainerMarketPlaceContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.17) 3px 3px 18px 3px;
    width: 80%;
    margin-top: -7%;
    border-radius: 10px;
    padding: 1%;

    @media (max-width: 767px) {
        display:none;
    }

`;

const MarketPlaceColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    margin-left: 20px;

    @media (min-width: 641px) and (max-width: 1023px) {
        width:45%;
    }

`;

const IconCell = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    margin-left: 20px;
`;

const MarketImg = styled.img`
    width: 20%;
    margin-right: 14px;

    @media (min-width: 768px) and (max-width: 1023px) {
        width:45px;
    }
`;

const IconItem = styled.div`
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 60%;
    flex-grow: 1;
    color: #434343;
    word-break: break-word;
`;

const Title = styled.h3`
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px !important;
`;

const Text = styled.p`
    font-size: 12px;
    margin-top: 6px;
    line-height: 1.8em;
`;

const DashedBorder = styled.div`
    border-bottom: 1px dashed rgba(128, 128, 128, 0.23922);
    width:100%;
`;

const PictureFooter = styled.img`

    width: 440px;
    bottom: -170px;

    @media (max-width: 1024px) and (orientation: landscape) {
        display:none !important;
    }

`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content:center;
    flex-wrap:wrap;
    background-color:#217EE6;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    padding: 2% 9%;
    position:relative;

    @media (max-width: 767px) {
        justify-content: center;
    }

`;


const Scroll = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -6%;
    margin-bottom:14%;
    width: 100%;

    @media (min-width: 320px) and (max-width: 1024px) {
        white-space: nowrap;
        overflow-x: auto;
        justify-content: flex-start;
        -webkit-overflow-scrolling: touch;
        margin-left: 2%;
        margin-bottom:0%;
    }

    @media (min-width: 767px) {
        display:none;
    }
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 235px;
    background-color: #ffffff;
    color: #434343;
    border-radius: 10px;
    padding : 4% 3% 0% 3%;
    box-shadow: 1px 2px 7px 3px rgba(99, 99, 99, .16863);
    margin: 1%;

    @media (min-width: 320px) and (max-width: 1024px) {
       padding:0% 8%;
       flex-shrink: 0;
       width:220px;
    }
`;

const CellIcon = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px dashed #8080803d;
    padding: 20px 0;

`;


const IconCard = styled.img`
    max-width: 50px;
    margin-right: 7%;
`;


const IconText = styled.p`
    color: #434343;
    font-size:14px;
    font-weight:400;
`;


const LinkFooter = styled(LocalizedLink)`
`;


const MarketConnector = ({
    imageUrl,
    titleIcon,
    textIcon
}) => {
    return (
        <MarketPlaceColumn>
            <IconCell>
                <MarketImg src={imageUrl}
                    alt='circle picture media' />
                <IconItem>
                    <Title>{titleIcon}</Title>
                    <Text dangerouslySetInnerHTML={{ __html: textIcon }}></Text>
                </IconItem>
            </IconCell>
            <DashedBorder></DashedBorder>
        </MarketPlaceColumn>
    );
};

MarketConnector.propTypes = {
    imageUrl: PropTypes.string,
    titleIcon: PropTypes.string,
    textIcon: PropTypes.string
};

const InfosCard = ({
    firstImageUrl,
    firstTextIcon,
    secondTextIcon,
    secondImageUrl,
    thirdTextIcon,
    thirdImageUrl,
    fourthTextIcon,
    fourthImageUrl,
    fifthTextIcon,
    fifthImageUrl,
    sixthTextIcon,
    sixthImageUrl
}) => {
    return (
        <Card>
            <CellIcon>
                <IconCard alt={`Representation of ${firstTextIcon} icon`}
                    src={firstImageUrl} />
                <IconText>{firstTextIcon}</IconText>
            </CellIcon>
            <CellIcon>
                <IconCard alt={`Representation of ${secondTextIcon} icon`}
                    src={secondImageUrl} />
                <IconText>{secondTextIcon}</IconText>
            </CellIcon>
            <CellIcon>
                <IconCard alt={`Representation of ${thirdTextIcon} icon`}
                    src={thirdImageUrl} />
                <IconText>{thirdTextIcon}</IconText>
            </CellIcon>
            <CellIcon>
                <IconCard alt={`Representation of ${fourthTextIcon} icon`}
                    src={fourthImageUrl} />
                <IconText>{fourthTextIcon}</IconText>
            </CellIcon>
            <CellIcon>
                <IconCard alt={`Representation of ${fifthTextIcon} icon`}
                    src={fifthImageUrl} />
                <IconText>{fifthTextIcon}</IconText>
            </CellIcon>
            <CellIcon>
                {sixthImageUrl ? <IconCard alt={`Representation of ${sixthTextIcon} icon`}
                    src={sixthImageUrl} /> : null}
                {sixthTextIcon ? <IconText>{sixthTextIcon}</IconText> : null}
            </CellIcon>
        </Card>
    );
};

InfosCard.propTypes = {
    firstImageUrl: PropTypes.string,
    firstTextIcon: PropTypes.string,
    secondTextIcon: PropTypes.string,
    secondImageUrl: PropTypes.string,
    thirdTextIcon: PropTypes.string,
    thirdImageUrl: PropTypes.string,
    fourthTextIcon: PropTypes.string,
    fourthImageUrl: PropTypes.string,
    fifthTextIcon: PropTypes.string,
    fifthImageUrl: PropTypes.string,
    sixthTextIcon: PropTypes.string,
    sixthImageUrl: PropTypes.string
};

const SectionHeaderWithFooter = ({
    titleSection, pictureFooter, infosCards, altDescription, urlFooter, titleButton, marketIconsConnector
}) => {

    const marketConnector = marketIconsConnector.map(i => {
        return (<MarketConnector imageUrl={i.imageUrl}
            titleIcon={i.titleIcon}
            textIcon={i.textIcon} />);
    });
    const infosCard = infosCards ? infosCards.map(i => {
        return (<InfosCard
            firstImageUrl={i.firstImageUrl}
            secondImageUrl={i.secondImageUrl}
            thirdImageUrl={i.thirdImageUrl}
            fourthImageUrl={i.fourthImageUrl}
            fifthImageUrl={i.fifthImageUrl}
            sixthImageUrl={i.sixthImageUrl}
            firstTextIcon={i.firstTextIcon}
            secondTextIcon={i.secondTextIcon}
            thirdTextIcon={i.thirdTextIcon}
            fourthTextIcon={i.fourthTextIcon}
            fifthTextIcon={i.fifthTextIcon}
            sixthTextIcon={i.sixthTextIcon}
        />);
    }) : null;


    return (
        <Layout>

            <Header>
                <LeftCellTitle dangerouslySetInnerHTML={{ __html: titleSection }}></LeftCellTitle>
            </Header>

            <MarketLayout>
                <Scroll>
                    {infosCard}
                </Scroll>
                <ContainerMarketPlaceContent>
                    {marketConnector}
                </ContainerMarketPlaceContent>
            </MarketLayout>

            <Footer>
                {pictureFooter ? <PictureFooter src={pictureFooter}
                    alt={altDescription} /> : null}
                <LinkFooter to={urlFooter}>
                    <PrimaryButton
                        backgroundColor='#ffffff'
                        color='#217EE6'
                        borderColor='#ffffff'
                        hoverColor='#ffffff'
                        hoverBorderColor='##ffffff'
                        titleButton={titleButton} />
                </LinkFooter>
            </Footer>

        </Layout>
    );
};

SectionHeaderWithFooter.propTypes = {
    titleSection: PropTypes.string,
    pictureFooter: PropTypes.string,
    infosCards: PropTypes.array,
    altDescription: PropTypes.string,
    urlFooter: PropTypes.string,
    titleButton: PropTypes.string,
    marketIconsConnector: PropTypes.array
};

export default React.memo(SectionHeaderWithFooter);
